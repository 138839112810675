import { render, staticRenderFns } from "./AdminInformationForm.vue?vue&type=template&id=a3b01954&scoped=true"
import script from "./AdminInformationForm.vue?vue&type=script&setup=true&lang=js"
export * from "./AdminInformationForm.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AdminInformationForm.vue?vue&type=style&index=0&id=a3b01954&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b01954",
  null
  
)

export default component.exports