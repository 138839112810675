<template>
	<main>
		<form @submit.prevent="manageAdmin">
			<h4>Admin Information</h4>
			<small class="text-muted">Information about the key personnel that will manage the companny
				account.</small>
			<hr class="my-4" />
			<div class="col-12">
				<div class="form-group">
					<label class="form-label"> First name </label>
					<input v-model.trim="company.fname" required type="text" class="form-control" />
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label class="form-label"> Last name </label>
					<input v-model.trim="company.lname" required type="text" class="form-control" />
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label class="mb-1"> Email address </label>
					<input v-model.trim="company.person_email" required type="email" class="form-control" />
				</div>
			</div>
			<div class="col-12">
				<div class="form-group">
					<label class="form-label"> Phone </label>
					<input v-model.trim="company.person_phone" required type="text" class="form-control mb-3"
						placeholder="(___)___-____" inputmode="text" />
				</div>
			</div>

			<div class="col-12 passwordParent">
				<div class="form-group">
					<label class="form-label"> Password </label>
					<input v-model.trim="company.password" required :type="showPassword ? 'text' : 'password'"
						class="form-control mb-3" placeholder="" />
				</div>
				<p :style="[showPassword ? 'color: green' : 'color : red']" @click="togglePassword" class="passwordEye"
					style="cursor: pointer">
					{{ showPassword ? 'hide' : 'show' }}
				</p>
			</div>

			<button :disabled="!isFormValid || processing" type="submit"
				:class="!isFormValid || processing ? 'btn-secondary' : 'btn-primary'" class="btn mt-4 w-25">
				{{ processing ? 'Processing...' : 'Create' }}
				<span class="ml-2"><img src="@/assets/img/icons/forward-icon.png" alt="" /></span>
			</button>
		</form>
	</main>
</template>

<script setup>
import { computed, ref } from 'vue'
// name: 'AdminInformationForm'
defineProps({
  companyData: {
    type: Object
  }
})

const processing = ref(false)
const showPassword = ref(false)
const company = ref({
  fname: '',
  lname: '',
  person_email: '',
  person_phone: '',
  password: ''
})

const emit = defineEmits(['completedAdminForm'])
const manageAdmin = () => {
  emit('completedAdminForm', company.value)
}

const togglePassword = () => {
  showPassword.value = !showPassword.value
}

const isFormValid = computed(() => {
  return !!(company.value.fname &&
    company.value.lname &&
    company.value.person_email &&
    company.value.person_phone &&
    company.value.password)
}
)
</script>

<style scoped>
.passwordParent {
  position: relative;
}

.passwordEye {
  position: absolute;
  top: 40px;

  right: 30px;
}
</style>
